.panel {
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 5%;
}

.panel .panelContainer {
    width: 100%;
    height: 100%;
    margin-left: 50%;
    max-width: 1140px;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.panel .panelContainer h1 {
    color: #3F3D3D;
    font-family: 'Montserrat', sans-serif;
    font-weight: bolder;
    text-align: center;
}

.panel .panelContainer p {
    color: #978E8E;
    font-family: 'Montserrat', sans-serif;
    margin-top: 15px;
    text-align: center;
    width: 85%;
}

.panel .panelContainer a {
    padding: 15px;
    margin-top: 5%;
    background: none;
    border: 1px solid #960000;
    border-radius: 8px;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    color: #978E8E;
    transition: all .6s ease;
    text-decoration: none;
    font-size: 15px;
    
}

.panel .panelContainer a:hover {
    color: white;
    background: #960000;
}
