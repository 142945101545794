.tech {
    position: relative;
    width: 100%;
    height: 450px;
    margin-top: 8%;
}

.tech .techBg {
    position: absolute;
    height: 450px;
    left: 0;
    top: 0;
    width: 40%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background: -moz-linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(212, 231, 250, 1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(212, 231, 250, 1) 100%);
    background: linear-gradient(90deg, rgba(0,0,0,0)  0%,rgba(212, 231, 250, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#d4e7fa',GradientType=1 );
}

.tech .techContainer {
    width: 100%;
    height: 100%;
    margin-left: 50%;
    max-width: 1140px;
    transform: translateX(-50%);
    display: flex;
}

.tech .techContainer .techLeft {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.tech .techContainer .techLeft .techImg {
    width: 80%;
    margin-left: 15%;
}

.tech .techContainer .techRight {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.tech .techContainer .techRight h1 {
    color: #3F3D3D;
    font-family: 'Montserrat', sans-serif;
    font-weight: bolder;
    text-align: center;
}

.tech .techContainer .techRight p {
    width: 85%;
    color: #978E8E;
    font-family: 'Montserrat', sans-serif;
    margin-top: 15px;
    text-align: center;
}

.tech .techContainer .techRight a {
    padding: 15px;
    margin-top: 5%;
    background: none;
    border: 1px solid #960000;
    border-radius: 8px;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    color: #978E8E;
    transition: all .6s ease;
    text-decoration: none;
    font-size: 15px;
}

.tech .techContainer .techRight a:hover {
    color: white;
    background: #960000;
}

.techRight a {
    text-decoration: none;
    color: #978E8E;
}

.techRight a:hover{
    color: white;
}