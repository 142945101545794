.modal {
    border-style: double;
    border-color: black;
    border-radius: 10px;
    position: absolute;
    padding-top: 20px;
    text-align: center;
    background-color: #960000;
    color: white;
    box-shadow: 0px 50px 50px 0px rgba(0, 0, 0, 0.63);
}

.modalBody {
    border-style: none;
    background-color: white;
    display: inherit;
    color: #960000;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

.modalBody label {
    float: left;
    font-size: 12px;
    margin-top: 15px;
    font-weight: bolder;
}

#send {
    color: white;
    margin: 10px;
}

#send:hover {
    background-color: green;
}

#close{
    color: white;
}

#close:hover {
    background-color: gray;
}

#inputName {
    padding: 5px 90px 0px 35px;
    border-radius: 5px;
    border-width: 1px;
    border-color: #960000;
}

#inputEmail {
    padding: 5px 90px 0px 35px;
    border-radius: 5px;
    border-width: 1px;
    border-color: #960000;
}

#inputTelefone {
    padding: 5px 90px 0px 35px;
    border-radius: 5px;
    border-width: 1px;
    border-color: #960000;
}

.modalBodyInformations {
    margin-left: -55px;
    padding: 75px 83px 60px 150px;
}
