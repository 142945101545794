.servVideo {
    width: 100%;
    height: 450px;
    position: relative;
    overflow: hidden;
}

.servVideo h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0 auto;
    color: white;
    z-index: 100;
    font-family: 'Montserrat', sans-serif;
    font-size: 2rem;
    text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    transform: translateX(-50%);
}

.servVideo video {
    width: 100%;
    margin-top: -20%;
    filter: brightness(80%);
}

.servContainer {
    background: white;
    position: absolute;
    top: 400px;
    left: 0;
    width: 100%;
    height: auto;
    margin-left: 50%;
    max-width: 1140px;
    transform: translateX(-50%);
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    padding: 20px;
    padding-top: 50px;
}

.servContainer .servPanel {
    margin-bottom: 5%;
}

.servContainer .servPanel h1 {
    color: #3F3D3D;
    font-family: 'Montserrat', sans-serif;
    font-weight: bolder;
    width: 100%;
    text-align: left;
    font-size: 2rem;
}

.servContainer .servPanel h1::after {
    content: '';
    display: block;
    margin: 5px 0;
    background: none;
    border-bottom: 1px solid #960000;
    width: 50%;
}

.servContainer .servPanel p {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.9rem;
}

.servContainer .servPanel ul {
    width: 100%;
    list-style: none;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    margin-top: 40px;
}

.servContainer .servPanel ul li {
    width: 250px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    border-radius: 5px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    padding: 5px;
}

.servContainer .servPanel ul li .servFace1 {
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
    opacity: 0.3;
    transform: translateY(50px);
}

.servContainer .servPanel ul li:hover .servFace1 {
    transform: translateY(0px);
    opacity: 1;
}

.servContainer .servPanel ul li:hover .servFace1 .iconFont {
    color: royalblue;
}

.servContainer .servPanel ul li .servFace1 .iconFont {
    font-size: 3rem;
    margin-bottom: 10px;
}

.servContainer .servPanel ul li .servFace1 .arrowIcon {
    margin-top: 5px;
    font-size: 1.2rem;
}

.servContainer .servPanel ul li .servFace2 {
    z-index: -5;
    transition: all 0.5s ease;
    transform: translateY(-75px);
    opacity: 0;
    padding: 5px;
}

.servContainer .servPanel ul li .servFace2 hr {
    height: 3px;
    width: 50%;
    margin: 0 auto;
    background: #960000;
    margin-bottom: 5px;
}

.servContainer .servPanel ul li .servFace2 p {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.8rem;
    font-style: italic;
}

.servContainer .servPanel ul li:hover .servFace2 {
    opacity: 1;
}

/*.serv {
    position: absolute;
    width: 100%;
    margin-top: 180px;
}

.serv .servContainer {
    width: 100%;
    height: 100%;
    margin-left: 50%;
    max-width: 1140px;
    transform: translateX(-50%);
    display: flex;
    margin-top: 5%;
    flex-direction: column;
}

.serv .servContainer .servPanel {
    display: flex;
    width: 100%;
    flex-direction: row;
    margin-bottom: 5%;
}

.serv .servContainer .servPanel .servLeft {
    width: 30%;
    padding: 20px;
    overflow: hidden;
    position: relative;
}

.serv .servContainer .servPanel .servLeft .servImg {
    width: 100%;
    filter: blur(2px);
    border-radius: 5px;
}

.serv .servContainer .servPanel .servLeft h2 {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: bolder;
    width: 100%;
    text-align: center;
    text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
}

.serv .servContainer .servPanel .servRight {
    width: 70%;
    padding: 20px;
}

.serv .servContainer .servPanel .servRight p {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.8rem;
}

.serv .servContainer .servPanel .servRight ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    align-content: center;
    height: 100%;
}

.serv .servContainer .servPanel .servRight ul li {
    width: 130px;
    height: 130px;
    background: white;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    border-radius: 10px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
    color: #3F3D3D;
    padding: 5px;
}

.serv .servContainer .servPanel .servRight ul li .iconFont {
    font-size: 2.5rem;
    margin: 0 auto;
    margin-bottom: 5px;
    color: #3F3D3D;
}*/