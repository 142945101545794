@import url(https://fonts.googleapis.com/css?family=Montserrat:600,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:600i&display=swap);
.sliderImage {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.sliderImage .slide {
    min-width: 100%;
    height: 100vh;
    position: relative;
    transition: all 0.5s ease-in;
    overflow: hidden;
}

.sliderImage .slide .sliderContent,
.sliderImage .slideControlsContainer {
    position: absolute;
    left: 50%;
    width: 100%;
    max-width: 1140px;
    transform: translateX(-50%);
}

.sliderImage .slide .sliderContent {
    top: 0;
    height: 100vh;
}

.sliderImage .slideControlsContainer {
    bottom: 0;
    height: 20vh;
}

.sliderImage .slide .slideImage {
    width: 100%;
    height: auto;
    -webkit-filter: brightness(80%);
            filter: brightness(80%);
}

.sliderImage .slide .sliderContent {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
}

.sliderImage .slide .sliderContent .primaryText,
.sliderImage .slide .sliderContent .secondaryText {
    width: 40%;
    height: auto;
    padding: 1rem;
    color: white;
    text-align: right;
    font-family: 'Montserrat', sans-serif;
    
}

.sliderImage .slide .sliderContent .primaryText {
    font-size: 2rem;
}

.sliderImage .slide .sliderContent .secondaryText {
    font-size: 0.8rem;
    word-wrap: break-word;
}

.sliderImage .slide .sliderContent .btnSlider {
    padding: 1rem;
    outline: none;
    border: none;
    background: #960000;
    color: white;
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;
    font-size: 1rem;
    margin-right: 1rem;
    transition: all 0.3s ease-in-out;
}

.sliderImage .slide .sliderContent .btnSlider:hover {
    background: rgba(150, 0, 0, 0.2);
    border-radius: 12px;
}

.sliderImage .slide .sliderContent .btnSlider:hover span {
    -webkit-text-decoration: underline #960000;
            text-decoration: underline #960000;
    text-underline-position: under;
    color: #960000;
}

.sliderImage .slideControlsContainer .slideControlsContent {
    width: 100%;
    height: 50px;
    position: absolute;
    /*right: 2%;*/
    bottom: 5vh;
    border-top: 3px solid #960000;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.sliderImage .slideControlsContainer .slideControlsContent button {
    width: 25px;
    height: 25px;
    border: none;
    border-radius: 50%;
    outline: none;
    background: transparent;
    color: #960000;
    font-size: 1.3rem;
    margin: 0 10px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sliderImage .slideControlsContainer .slideControlsContent button:hover {
    background: #960000;
    color: white;
    border-radius: 50%;
}

.sliderImage .slideControlsContainer .slideControlsContent span {
    color: #960000;
    font-size: 0.8rem;
}
.layers {
    position: relative;
    width: 100%;
    height: 500px;
    /*display: none;*/
}

.fondito {
    display: block;
    -webkit-animation: slide-in 1s forwards;
    animation: slide-in 1s forwards;
}

@-webkit-keyframes slide-in {
    100% { left: 0 }
}

@keyframes slide-in {
    100% { left: 0 }
}

.layers .layersContainer {
    width: 100%;
    height: 100%;
    margin-left: 50%;
    max-width: 1140px;
    transform: translateX(-50%);
    display: flex;
}

.layers .layersContainer .leftSide {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.layers .layersContainer .leftSide h1 {
    color: #3F3D3D;
    font-family: 'Montserrat', sans-serif;
    font-weight: bolder;   
}

.layers .layersContainer .leftSide h1::after {
    content: '';
    display: block;
    margin: 5px auto;
    background: none;
    border-bottom: 1px solid #960000;
    width: 80%;
}

.layers .layersContainer .leftSide p {
    text-align: center;
    width: 85%;
    color: #978E8E;
    font-family: 'Montserrat', sans-serif;
    margin-top: 15px;
}

.layers .layersContainer .rightSide {
    width: 50%;
    position: relative;
    /*background: yellow;*/
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.layers .layersContainer .rightSide .cards ul {
    list-style: none;
    text-align: center;
    position: absolute;
    left: 10%;
    top: 20%;
    width: 100%;
    height: 100%;
    z-index: 100;
    /*transform: translate(-50%,-50%);*/
}

.layers .layersContainer .rightSide .cards h1,
.layers .layersContainer .rightSide .cards span,
.layers .layersContainer .rightSide .cards a {
    z-index: 99;
    position: relative;
}

.layers .layersContainer .rightSide .cards ul li {
    display: block;
    position: absolute;
    background-color: #fefefe;
    width: 250px;
    height: 320px;
    top: 0;
    left: 0;
    transform: rotate(10deg) skew(-60deg);
    transform-origin: bottom center;
    box-shadow: 10px 10px 30px rgba(0,0,0,0.3);
    transition: 0.4s;
    z-index: -1;
    overflow: hidden;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 15px;
}

.layers .layersContainer .rightSide .cards ul li::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10px;
    transition:.6s;
}

.layers .layersContainer .rightSide .cards ul li:nth-child(3)::after {
    background: linear-gradient(45deg, rgba(177, 2, 2, 1) 0%,rgba(0,0,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b10202', endColorstr='#000000',GradientType=1 );
}

.layers .layersContainer .rightSide .cards ul li:nth-child(2)::after {
    background: linear-gradient(45deg, rgba(5, 107, 5, 1) 0%,rgba(0,0,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#056b05', endColorstr='#000000',GradientType=1 );
}

.layers .layersContainer .rightSide .cards ul li:nth-child(1)::after {
    background: rgb(10, 10, 167);
    background: linear-gradient(45deg, rgba(10, 10, 167, 1) 0%,rgba(0,0,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0a0aa7', endColorstr='#000000',GradientType=1 );
}

.layers .layersContainer .rightSide .cards ul li h1 {
    font-size: 2em;
    margin-top: 8%;
    margin-bottom: 10%;
    transition: .4s;
}

.layers .layersContainer .rightSide .cards ul li:nth-child(3) h1 {
    background: rgb(177, 2, 2);
    -webkit-background-clip: text;
            background-clip: text;
    -webkit-text-fill-color: transparent;
}

.layers .layersContainer .rightSide .cards ul li:nth-child(2) h1 {
    background: rgb(5, 107, 5);
    -webkit-background-clip: text;
            background-clip: text;
    -webkit-text-fill-color: transparent;
}

.layers .layersContainer .rightSide .cards ul li:nth-child(1) h1 {
    background: rgb(10, 10, 167);
    -webkit-background-clip: text;
            background-clip: text;
    -webkit-text-fill-color: transparent;
}

.layers .layersContainer .rightSide .cards ul li > span {
    font-size: 1.1em;
    color: #999999;
}

.layers .layersContainer .rightSide .cards ul li:nth-child(1) {
    transform:  rotate(20deg) skew(-20deg) translate(10px,10px); 
}
 
.layers .layersContainer .rightSide .cards ul li:nth-child(2) {
    transform:  rotate(20deg) skew(-20deg) translate(0px,0px); 
}

.layers .layersContainer .rightSide .cards ul li:nth-child(3) {
    transform:  rotate(20deg) skew(-20deg) translate(-10px,-10px); 
}

.layers .layersContainer .rightSide .cards ul:hover li:nth-child(1) {
    transform:  rotate(40deg) skew(0) translate(100px,0) ; 
}

.layers .layersContainer .rightSide .cards ul:hover li:nth-child(2) {
    transform:  rotate(0) skew(0); 
}

.layers .layersContainer .rightSide .cards ul:hover li:nth-child(3) {
    transform:  rotate(-40deg) skew(0) translate(-100px,0) ; 
}

.layers .layersContainer .rightSide .cards ul:hover li:hover {
    z-index: 9;
    background-color: #0e1826;
    box-shadow:10px 10px 40px rgba(0,0,0,0.5) ;
} 
 
.layers .layersContainer .rightSide .cards ul:hover li:hover::after {
    height:100%;
 /*   transform:skew(30deg); */
}

.layers .layersContainer .rightSide .cards ul:hover li:hover * {
    color: #fff;
    background: -webkit-linear-gradient(#fff, #fff);
    -webkit-background-clip: text;
            background-clip: text;
    -webkit-text-fill-color: transparent;
}
.tech {
    position: relative;
    width: 100%;
    height: 450px;
    margin-top: 8%;
}

.tech .techBg {
    position: absolute;
    height: 450px;
    left: 0;
    top: 0;
    width: 40%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background: linear-gradient(90deg, rgba(0,0,0,0)  0%,rgba(212, 231, 250, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#d4e7fa',GradientType=1 );
}

.tech .techContainer {
    width: 100%;
    height: 100%;
    margin-left: 50%;
    max-width: 1140px;
    transform: translateX(-50%);
    display: flex;
}

.tech .techContainer .techLeft {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.tech .techContainer .techLeft .techImg {
    width: 80%;
    margin-left: 15%;
}

.tech .techContainer .techRight {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.tech .techContainer .techRight h1 {
    color: #3F3D3D;
    font-family: 'Montserrat', sans-serif;
    font-weight: bolder;
    text-align: center;
}

.tech .techContainer .techRight p {
    width: 85%;
    color: #978E8E;
    font-family: 'Montserrat', sans-serif;
    margin-top: 15px;
    text-align: center;
}

.tech .techContainer .techRight a {
    padding: 15px;
    margin-top: 5%;
    background: none;
    border: 1px solid #960000;
    border-radius: 8px;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    color: #978E8E;
    transition: all .6s ease;
    text-decoration: none;
    font-size: 15px;
}

.tech .techContainer .techRight a:hover {
    color: white;
    background: #960000;
}

.techRight a {
    text-decoration: none;
    color: #978E8E;
}

.techRight a:hover{
    color: white;
}
.mobile {
    position: relative;
    width: 100%;
    height: 450px;
    margin-top: 5%;
}

.mobile .mobileBg {
    position: absolute;
    height: 450px;
    right: 0;
    top: 0;
    width: 40%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background: linear-gradient(90deg, rgba(212, 231, 250, 1)  0%, rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d4e7fa', endColorstr='#000000',GradientType=1 );
}

.mobile .mobileContainer {
    width: 100%;
    height: 100%;
    margin-left: 50%;
    max-width: 1140px;
    transform: translateX(-50%);
    display: flex;
}

.mobile .mobileContainer .mobileLeft {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mobile .mobileContainer .mobileLeft h1 {
    color: #3F3D3D;
    font-family: 'Montserrat', sans-serif;
    font-weight: bolder;
    text-align: center;
}

.mobile .mobileContainer .mobileLeft p {
    width: 85%;
    color: #978E8E;
    font-family: 'Montserrat', sans-serif;
    margin-top: 15px;
    text-align: center;
}

.mobile .mobileContainer .mobileLeft a {
    padding: 15px;
    margin-top: 5%;
    background: none;
    border: 1px solid #960000;
    border-radius: 8px;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    color: #978E8E;
    transition: all .6s ease;
    text-decoration: none;
    font-size: 15px;
}

.mobile .mobileContainer .mobileLeft a:hover {
    color: white;
    background: #960000;
}

.mobile .mobileContainer .mobileRight {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mobile .mobileContainer .mobileRight .mobileImg {
    width: 70%;
    margin-right: 30%;
}


.modal {
    border-style: double;
    border-color: black;
    border-radius: 10px;
    position: absolute;
    padding-top: 20px;
    text-align: center;
    background-color: #960000;
    color: white;
    box-shadow: 0px 50px 50px 0px rgba(0, 0, 0, 0.63);
}

.modalBody {
    border-style: none;
    background-color: white;
    display: inherit;
    color: #960000;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

.modalBody label {
    float: left;
    font-size: 12px;
    margin-top: 15px;
    font-weight: bolder;
}

#send {
    color: white;
    margin: 10px;
}

#send:hover {
    background-color: green;
}

#close{
    color: white;
}

#close:hover {
    background-color: gray;
}

#inputName {
    padding: 5px 90px 0px 35px;
    border-radius: 5px;
    border-width: 1px;
    border-color: #960000;
}

#inputEmail {
    padding: 5px 90px 0px 35px;
    border-radius: 5px;
    border-width: 1px;
    border-color: #960000;
}

#inputTelefone {
    padding: 5px 90px 0px 35px;
    border-radius: 5px;
    border-width: 1px;
    border-color: #960000;
}

.modalBodyInformations {
    margin-left: -55px;
    padding: 75px 83px 60px 150px;
}

.panel {
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 5%;
}

.panel .panelContainer {
    width: 100%;
    height: 100%;
    margin-left: 50%;
    max-width: 1140px;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.panel .panelContainer h1 {
    color: #3F3D3D;
    font-family: 'Montserrat', sans-serif;
    font-weight: bolder;
    text-align: center;
}

.panel .panelContainer p {
    color: #978E8E;
    font-family: 'Montserrat', sans-serif;
    margin-top: 15px;
    text-align: center;
    width: 85%;
}

.panel .panelContainer a {
    padding: 15px;
    margin-top: 5%;
    background: none;
    border: 1px solid #960000;
    border-radius: 8px;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    color: #978E8E;
    transition: all .6s ease;
    text-decoration: none;
    font-size: 15px;
    
}

.panel .panelContainer a:hover {
    color: white;
    background: #960000;
}

.services {
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 5%;
    background: rgba(170, 170, 170, 0.12);
    padding: 20px;
}

.services .servicesContainer {
    width: 100%;
    height: 100%;
    margin-left: 50%;
    max-width: 1140px;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.services .servicesContainer h1 {
    color: #3F3D3D;
    font-family: 'Montserrat', sans-serif;
    font-weight: bolder;
    text-align: center;
}

.services .servicesContainer h1::after {
    content: '';
    display: block;
    margin: 5px auto;
    background: none;
    border-bottom: 1px solid #960000;
    width: 80%;
}

.services .servicesContainer p {
    color: #978E8E;
    font-family: 'Montserrat', sans-serif;
    margin-top: 8px;
    text-align: center;
    width: 85%;
}

.services .servicesContainer .serviceCards {
    width: 85%;
    margin-top: 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.services .servicesContainer .serviceCards .serviceCard {
    width: 200px;
    height: 330px;
    border-radius: 8px;
    box-shadow: 2px 5px 4px #888888;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background: white;
    transition: all 0.6s ease;
}

.services .servicesContainer .serviceCards .serviceCard:hover {
    transform: translateY(-20px);
}

.services .servicesContainer .serviceCards .serviceCard img {
    width: 160px;
    border-radius: 10px;
    margin-top: 20px;
}

.services .servicesContainer .serviceCards .serviceCard h3 {
    color: black;
    font-family: 'Montserrat', sans-serif;
    font-weight: lighter;
    font-style: italic;
    margin-top: 12px;
    text-align: center;
    width: 85%;
}

.services .servicesContainer .serviceCards .serviceCard p {
    font-family: 'Open Sans', sans-serif;
    font-style: italic;
    margin-top: 12px;
}

.serviceCard a {
    font-size: 15px;
    text-align: center;
}

.services .servicesContainer .serviceCards .serviceCard a {
    padding: 10px;
    margin-top: 5%;
    background: none;
    border: 1px solid #960000;
    border-radius: 8px;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    color: #978E8E;
    transition: all .6s ease;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 5%;
    width: 70%;
    text-decoration: none;
}

.services .servicesContainer .serviceCards .serviceCard a:hover {
    color: white;
    background: #960000;
}

.sliderSm {
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 5%;
    padding-bottom: 2%;
}

.sliderSm .sliderSmContainer {
    width: 100%;
    height: 100%;
    margin-left: 50%;
    max-width: 1140px;
    transform: translateX(-50%);
    position: relative;
}

.sliderSm .sliderSmContainer h1 {
    color: #3F3D3D;
    font-family: 'Montserrat', sans-serif;
    font-weight: bolder;
    text-align: center;
}

.sliderSm .sliderSmContainer .slideSm {    
    overflow: hidden;
    display: flex;
    flex-direction: row;
    width: 80%;
    margin: 0 auto;
    margin-top: 3%;
    transition: all 0.6s ease;
    padding-bottom: 1%;
}

.sliderSm .sliderSmContainer .slideSm .slideItem {
    width: 100px;
    height: 100px;
    border-radius: 8px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    min-width: 100px;
    margin-left: 30px;
    box-shadow: 3px 3px 8px rgba(0,0,0,0.5);
    transition: all 0.6s ease;
}

.sliderSm .sliderSmContainer .slideSm .slideItem .slideSmImage {
    width: 80%;
}

.sliderSm .sliderSmContainer .slideSmControls {
    width: 85%;
    position: absolute;
    top: 65%;
    left: 0;
    display: flex;
    margin-left: 51%;
    transform: translateX(-50%);
    justify-content: space-between;
}

.sliderSm .sliderSmContainer .slideSmControls button {
    width: 25px;
    height: 25px;
    border: none;
    border-radius: 50%;
    outline: none;
    background: transparent;
    color: #960000;
    font-size: 1.3rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sliderSm .sliderSmContainer .slideSmControls button:hover {
    background: #960000;
    color: white;
    border-radius: 50%;
}
footer {
    position: relative;
    width: 100%;
    height: auto;
    padding: 20px;
    /*background: rgba(212, 231, 250, 1);*/
}

footer .footerContainer {
    width: 100%;
    height: 100%;
    margin-left: 50%;
    max-width: 1140px;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
}

footer .footerContainer .footerLogo {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 25px;
}

footer .footerContainer .footerLogo .contactLogo {
    width: 40%;
}

footer .footerContainer .footerContact {
    width: 50%;
    display: flex;
    color: black;
    justify-content: flex-end;
    font-family: 'Montserrat', sans-serif;
    font-weight: lighter;
    font-size: 1rem;
}

footer .footerContainer .footerContact h4 {
    text-align: left;
}

footer .footerContainer .footerContact h4::after {
    content: '';
    display: block;
    margin: 5px 0;
    background: none;
    border-bottom: 1px solid #960000;
    width: 100%;
}

footer .footerContainer .footerContact ul {
    list-style: none;
    width: 80%;
}

footer .footerContainer .footerContact ul li {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 45px;
    margin-bottom: 5px;
    word-wrap: break-word;
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    font-size: 0.9rem;
    color: rgb(2, 5, 104);
}

footer .footerContainer .footerContact ul li .contactIcon {
    min-width: 45px;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    color: white;
    background: black;
    border-radius: 8px;
    margin-right: 15px;
}

footer .footerCopy p.copy {
    width: 100%;
    text-align: center;
    font-style: italic;
    font-size: 0.9rem;
    margin-top: 3%;
}
.aboutImg {
    width: 100%;
    height: 450px;
    background: url(/static/media/team.0605a8a1.jpg) no-repeat;
    background-size: 100%;
    background-position: center;
    position: relative;
}

.aboutContainer {
    background: white;
    position: absolute;
    top: 400px;
    left: 0;
    width: 100%;
    height: auto;
    margin-left: 50%;
    max-width: 1140px;
    transform: translateX(-50%);
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    padding: 20px;
    padding-top: 50px;
}

.aboutContainer .aboutNos {
    display: flex;
    flex-direction: row;
}

.aboutContainer .aboutNos .aboutNosImg {
    width: 25%;
}

.aboutContainer .aboutNos .aboutNosImg img {
    width: 50%;
    transform: translateX(50%);
}

.aboutContainer .aboutNos .aboutNosText {
    width: 75%;
}

.aboutContainer .aboutNos .aboutNosText h1 {
    color: #3F3D3D;
    font-family: 'Montserrat', sans-serif;
    font-weight: bolder;   
}

.aboutContainer .aboutNos .aboutNosText h1::after {
    content: '';
    display: block;
    margin: 5px 0px;
    background: none;
    border-bottom: 1px solid #960000;
    width: 50%;
}

.aboutContainer .aboutNos .aboutNosText p {
    font-style: italic;
}

.aboutContainer .aboutMVV {
    display: flex;
    justify-content: space-around;
    margin-top: 5%;
    margin-bottom: 5%;
}

.aboutContainer .aboutMVV .aboutColum {
    width: 300px;
    padding: 15px;
    border-radius: 15px;
    box-shadow: 5px 5px 5px rgba(0,0,0,0.3);
}

.aboutContainer .aboutMVV .aboutColum h1 {
    color: #3F3D3D;
    font-family: 'Montserrat', sans-serif;
    font-weight: bolder;   
    text-align: center;
}

.aboutContainer .aboutMVV .aboutColum h1::after {
    content: '';
    display: block;
    margin: 5px auto;
    background: none;
    border-bottom: 1px solid #960000;
    width: 20%;
}

.aboutContainer .aboutMVV .aboutColum p {
    margin-top: 4%;
    font-style: italic;
    line-height: 25px;
}
.servVideo {
    width: 100%;
    height: 450px;
    position: relative;
    overflow: hidden;
}

.servVideo h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0 auto;
    color: white;
    z-index: 100;
    font-family: 'Montserrat', sans-serif;
    font-size: 2rem;
    text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    transform: translateX(-50%);
}

.servVideo video {
    width: 100%;
    margin-top: -20%;
    -webkit-filter: brightness(80%);
            filter: brightness(80%);
}

.servContainer {
    background: white;
    position: absolute;
    top: 400px;
    left: 0;
    width: 100%;
    height: auto;
    margin-left: 50%;
    max-width: 1140px;
    transform: translateX(-50%);
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    padding: 20px;
    padding-top: 50px;
}

.servContainer .servPanel {
    margin-bottom: 5%;
}

.servContainer .servPanel h1 {
    color: #3F3D3D;
    font-family: 'Montserrat', sans-serif;
    font-weight: bolder;
    width: 100%;
    text-align: left;
    font-size: 2rem;
}

.servContainer .servPanel h1::after {
    content: '';
    display: block;
    margin: 5px 0;
    background: none;
    border-bottom: 1px solid #960000;
    width: 50%;
}

.servContainer .servPanel p {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.9rem;
}

.servContainer .servPanel ul {
    width: 100%;
    list-style: none;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    margin-top: 40px;
}

.servContainer .servPanel ul li {
    width: 250px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    border-radius: 5px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    padding: 5px;
}

.servContainer .servPanel ul li .servFace1 {
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
    opacity: 0.3;
    transform: translateY(50px);
}

.servContainer .servPanel ul li:hover .servFace1 {
    transform: translateY(0px);
    opacity: 1;
}

.servContainer .servPanel ul li:hover .servFace1 .iconFont {
    color: royalblue;
}

.servContainer .servPanel ul li .servFace1 .iconFont {
    font-size: 3rem;
    margin-bottom: 10px;
}

.servContainer .servPanel ul li .servFace1 .arrowIcon {
    margin-top: 5px;
    font-size: 1.2rem;
}

.servContainer .servPanel ul li .servFace2 {
    z-index: -5;
    transition: all 0.5s ease;
    transform: translateY(-75px);
    opacity: 0;
    padding: 5px;
}

.servContainer .servPanel ul li .servFace2 hr {
    height: 3px;
    width: 50%;
    margin: 0 auto;
    background: #960000;
    margin-bottom: 5px;
}

.servContainer .servPanel ul li .servFace2 p {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.8rem;
    font-style: italic;
}

.servContainer .servPanel ul li:hover .servFace2 {
    opacity: 1;
}

/*.serv {
    position: absolute;
    width: 100%;
    margin-top: 180px;
}

.serv .servContainer {
    width: 100%;
    height: 100%;
    margin-left: 50%;
    max-width: 1140px;
    transform: translateX(-50%);
    display: flex;
    margin-top: 5%;
    flex-direction: column;
}

.serv .servContainer .servPanel {
    display: flex;
    width: 100%;
    flex-direction: row;
    margin-bottom: 5%;
}

.serv .servContainer .servPanel .servLeft {
    width: 30%;
    padding: 20px;
    overflow: hidden;
    position: relative;
}

.serv .servContainer .servPanel .servLeft .servImg {
    width: 100%;
    filter: blur(2px);
    border-radius: 5px;
}

.serv .servContainer .servPanel .servLeft h2 {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: bolder;
    width: 100%;
    text-align: center;
    text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
}

.serv .servContainer .servPanel .servRight {
    width: 70%;
    padding: 20px;
}

.serv .servContainer .servPanel .servRight p {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.8rem;
}

.serv .servContainer .servPanel .servRight ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    align-content: center;
    height: 100%;
}

.serv .servContainer .servPanel .servRight ul li {
    width: 130px;
    height: 130px;
    background: white;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    border-radius: 10px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
    color: #3F3D3D;
    padding: 5px;
}

.serv .servContainer .servPanel .servRight ul li .iconFont {
    font-size: 2.5rem;
    margin: 0 auto;
    margin-bottom: 5px;
    color: #3F3D3D;
}*/
.construction {
        background: white;
        position: absolute;
        top: 300px;
        left: 0;
        width: 100%;
        height: auto;
        margin-left: 50%;
        max-width: 1140px;
        transform: translateX(-50%);
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        padding: 20px;
        margin-top: -160px;
        background: url(/static/media/construction.4862562a.png) - no-repeat;
        text-align: right;
}

.construction h1{
    color: black;
    text-align: left;
    font-family: 'Montserrat', sans-serif;
    font-size: 25px;
}
.contactImg {
    width: 100%;
    height: 450px;
    background: url(/static/media/contactus.95df0e72.jpg) no-repeat;
    background-size: 100%;
    background-position: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contactImg h1 {
    color: #3F3D3D;
    font-family: 'Montserrat', sans-serif;
    font-weight: bolder;
    text-align: center;
}

.contactContainer {
    background: white;
    position: absolute;
    top: 400px;
    left: 0;
    width: 100%;
    height: 50%;
    margin-left: 50%;
    max-width: 1140px;
    transform: translateX(-50%);
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    padding: 20px;
    padding-top: 50px;
    display: flex;
    flex-direction: row;
    margin-bottom: 10%;
}

.contactImg h1{
    margin-top: 10%;
}

.contactContainer .contactLeft {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contactContainer .contactLeft .contactLogo {
    width: 50%;
    margin-left: 35%;
}

.contactContainer .contactRight {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contactContainer .contactRight form {
    width: 60%;
    height: 90%;
}
.contactRight h1 {
    margin-top: -60%;
    width: 50%;
    margin-left: 15%;
}


.contactContainer .contactRight form .contactGrpControls {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.contactContainer .contactRight form .contactGrpControls label {
    color: #3F3D3D;
    font-family: 'Montserrat', sans-serif;
    font-weight: bolder;
    font-size: 0.8rem;
    margin-bottom: 5px;
}

.contactContainer .contactRight form .contactGrpControls input,
.contactContainer .contactRight form .contactGrpControls textarea {
    padding: 5px;
    border: 1px solid #3F3D3D;
    border-radius: 5px;
    outline: none;
}

.contactContainer .contactRight form button {
    padding: 15px;
    margin-top: 5%;
    background: none;
    border: 1px solid #960000;
    border-radius: 8px;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    color: #978E8E;
    transition: all .6s ease;
    width: 35%;
    margin-left: 50%;
    transform: translateX(-50%);
}

.contactContainer .contactRight form button:hover {
    color: white;
    background: #960000;
}

.principalNav {
    position: absolute;
    width: 100%;
    height: 15vh;
    background: rgba(255, 255, 255, 0.2);
    z-index: 100;
}

.principalNav .containerNav {
    position: absolute;
    left: 50%;
    width: 100%;
    height: 15vh;
    max-width: 1140px;
    transform: translateX(-50%);
    border-bottom: 2px solid #960000;
}

.principalNav .containerNav .loginBar {
    width: 100%;
    height: 30%;
    padding: 0;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
}

.principalNav .containerNav .language {
    width: 150px;
    height: 100%;
    display: inline-flex;
    align-items: center;
}

.principalNav .containerNav .loginBar .language .languageIcon {
    font-size: 1.5rem;
    margin-right: 5%;
    color: black;
}

.principalNav .containerNav .loginBar .language .contentSelect {
    position: relative;
    display: flex;
    align-items: center;
}

.principalNav .containerNav .loginBar .language .contentSelect .languageSelect {
    background: transparent;
    border: none;
    width: 120px;
    height: 30px;
    outline: none;
    font-family: 'Open Sans', sans-serif;
    font-style: italic;
    color: rgb(2, 5, 104);
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    transition: all 0.25s ease;
}

.principalNav .containerNav .loginBar .language .contentSelect .languageSelect:hover {
    font-weight: bold;
}

.principalNav .containerNav .loginBar .language .contentSelect .languageSelect::-ms-expand {
    display: none;
}

.principalNav .containerNav .loginBar .language .contentSelect i {
    position: absolute;
    right: 20px;
    top: calc(70% - 13px);
    width: 12px;
    height: 12px;
    display: block;
    border-left: 3px solid black;
    border-bottom: 3px solid black;
    transform: rotate(-45deg);
    transition: all 0.25s ease;
}

.principalNav .containerNav .loginBar .language .contentSelect:hover i {
    margin-top: 3px;
}

.principalNav .containerNav .loginBar .separator {
    width: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(2, 5, 104);
    font-weight: bolder;
    font-size: 1.4rem;
}

.principalNav .containerNav .loginBar .login {
    height: 100%;
    display: inline-flex;
    align-items: center;
    font-family: 'Open Sans', sans-serif;
    color: rgb(2, 5, 104);
    font-weight: bold;
    text-align: right;
    justify-content: flex-end;
    margin-left: 1%;
}

.principalNav .containerNav .loginBar .login .userIcon {
    font-size: 1rem;
    color: black;
}

.principalNav .containerNav .loginBar .login .userText {
    margin-left: 10px;
    font-style: italic;
}

.principalNav .containerNav .menuBar {
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: row;
}

.principalNav .containerNav .menuBar .logo {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.principalNav .containerNav .menuBar .logo .navLogo {
    width: 60%;
}

.principalNav .containerNav .menuBar .menu {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-family: 'Montserrat', sans-serif;
    color: black;
}

.principalNav .containerNav .menuBar .menu ul {
    list-style: none;
}

.principalNav .containerNav .menuBar .menu ul li {
    display: inline-flex;
    justify-content: space-between;
    width: 120px;
}

.principalNav .containerNav .menuBar .menu ul li a {
    text-decoration: none;
    transition: all 0.25s ease;
    color: black;
    position: relative;
    transition: all 0.25s ease;
}

.principalNav .containerNav .menuBar .menu ul li a::before {
    content: '>';
    color: #960000;
    top: 0;
    left: -15px;
    opacity: 0;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
}

.principalNav .containerNav .menuBar .menu ul li a::after {
    content: '<';
    color: #960000;
    top: 0;
    right: -15px;
    opacity: 0;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
}

.principalNav .containerNav .menuBar .menu ul li a:hover,
.principalNav .containerNav .menuBar .menu ul li a.active {
    color: #960000;
}

.principalNav .containerNav .menuBar .menu ul li a:hover::before,
.principalNav .containerNav .menuBar .menu ul li a.active::before {
    opacity: 1;
}

.principalNav .containerNav .menuBar .menu ul li a:hover::after,
.principalNav .containerNav .menuBar .menu ul li a.active::after {
    opacity: 1;
}
/* Importando Fuentes */

/* Estilos generales */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
