.sliderImage {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.sliderImage .slide {
    min-width: 100%;
    height: 100vh;
    position: relative;
    transition: all 0.5s ease-in;
    overflow: hidden;
}

.sliderImage .slide .sliderContent,
.sliderImage .slideControlsContainer {
    position: absolute;
    left: 50%;
    width: 100%;
    max-width: 1140px;
    transform: translateX(-50%);
}

.sliderImage .slide .sliderContent {
    top: 0;
    height: 100vh;
}

.sliderImage .slideControlsContainer {
    bottom: 0;
    height: 20vh;
}

.sliderImage .slide .slideImage {
    width: 100%;
    height: auto;
    filter: brightness(80%);
}

.sliderImage .slide .sliderContent {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
}

.sliderImage .slide .sliderContent .primaryText,
.sliderImage .slide .sliderContent .secondaryText {
    width: 40%;
    height: auto;
    padding: 1rem;
    color: white;
    text-align: right;
    font-family: 'Montserrat', sans-serif;
    
}

.sliderImage .slide .sliderContent .primaryText {
    font-size: 2rem;
}

.sliderImage .slide .sliderContent .secondaryText {
    font-size: 0.8rem;
    word-wrap: break-word;
}

.sliderImage .slide .sliderContent .btnSlider {
    padding: 1rem;
    outline: none;
    border: none;
    background: #960000;
    color: white;
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;
    font-size: 1rem;
    margin-right: 1rem;
    transition: all 0.3s ease-in-out;
}

.sliderImage .slide .sliderContent .btnSlider:hover {
    background: rgba(150, 0, 0, 0.2);
    border-radius: 12px;
}

.sliderImage .slide .sliderContent .btnSlider:hover span {
    text-decoration: underline #960000;
    text-underline-position: under;
    color: #960000;
}

.sliderImage .slideControlsContainer .slideControlsContent {
    width: 100%;
    height: 50px;
    position: absolute;
    /*right: 2%;*/
    bottom: 5vh;
    border-top: 3px solid #960000;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.sliderImage .slideControlsContainer .slideControlsContent button {
    width: 25px;
    height: 25px;
    border: none;
    border-radius: 50%;
    outline: none;
    background: transparent;
    color: #960000;
    font-size: 1.3rem;
    margin: 0 10px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sliderImage .slideControlsContainer .slideControlsContent button:hover {
    background: #960000;
    color: white;
    border-radius: 50%;
}

.sliderImage .slideControlsContainer .slideControlsContent span {
    color: #960000;
    font-size: 0.8rem;
}