.layers {
    position: relative;
    width: 100%;
    height: 500px;
    /*display: none;*/
}

.fondito {
    display: block;
    -webkit-animation: slide-in 1s forwards;
    animation: slide-in 1s forwards;
}

@-webkit-keyframes slide-in {
    100% { left: 0 }
}

@keyframes slide-in {
    100% { left: 0 }
}

.layers .layersContainer {
    width: 100%;
    height: 100%;
    margin-left: 50%;
    max-width: 1140px;
    transform: translateX(-50%);
    display: flex;
}

.layers .layersContainer .leftSide {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.layers .layersContainer .leftSide h1 {
    color: #3F3D3D;
    font-family: 'Montserrat', sans-serif;
    font-weight: bolder;   
}

.layers .layersContainer .leftSide h1::after {
    content: '';
    display: block;
    margin: 5px auto;
    background: none;
    border-bottom: 1px solid #960000;
    width: 80%;
}

.layers .layersContainer .leftSide p {
    text-align: center;
    width: 85%;
    color: #978E8E;
    font-family: 'Montserrat', sans-serif;
    margin-top: 15px;
}

.layers .layersContainer .rightSide {
    width: 50%;
    position: relative;
    /*background: yellow;*/
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.layers .layersContainer .rightSide .cards ul {
    list-style: none;
    text-align: center;
    position: absolute;
    left: 10%;
    top: 20%;
    width: 100%;
    height: 100%;
    z-index: 100;
    /*transform: translate(-50%,-50%);*/
}

.layers .layersContainer .rightSide .cards h1,
.layers .layersContainer .rightSide .cards span,
.layers .layersContainer .rightSide .cards a {
    z-index: 99;
    position: relative;
}

.layers .layersContainer .rightSide .cards ul li {
    display: block;
    position: absolute;
    background-color: #fefefe;
    width: 250px;
    height: 320px;
    top: 0;
    left: 0;
    transform: rotate(10deg) skew(-60deg);
    transform-origin: bottom center;
    box-shadow: 10px 10px 30px rgba(0,0,0,0.3);
    transition: 0.4s;
    z-index: -1;
    overflow: hidden;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 15px;
}

.layers .layersContainer .rightSide .cards ul li::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10px;
    transition:.6s;
}

.layers .layersContainer .rightSide .cards ul li:nth-child(3)::after {
    background: -moz-linear-gradient(45deg, rgba(177, 2, 2, 1) 0%, rgba(0,0,0,1) 100%);
    background: -webkit-linear-gradient(45deg, rgba(177, 2, 2, 1) 0%,rgba(0,0,0,1) 100%);
    background: linear-gradient(45deg, rgba(177, 2, 2, 1) 0%,rgba(0,0,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b10202', endColorstr='#000000',GradientType=1 );
}

.layers .layersContainer .rightSide .cards ul li:nth-child(2)::after {
    background: -moz-linear-gradient(45deg, rgba(5, 107, 5, 1) 0%, rgba(0,0,0,1) 100%);
    background: -webkit-linear-gradient(45deg, rgba(5, 107, 5, 1) 0%,rgba(0,0,0,1) 100%);
    background: linear-gradient(45deg, rgba(5, 107, 5, 1) 0%,rgba(0,0,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#056b05', endColorstr='#000000',GradientType=1 );
}

.layers .layersContainer .rightSide .cards ul li:nth-child(1)::after {
    background: rgb(10, 10, 167);
    background: -moz-linear-gradient(45deg, rgba(10, 10, 167, 1) 0%, rgba(0,0,0,1) 100%);
    background: -webkit-linear-gradient(45deg, rgba(10, 10, 167, 1) 0%,rgba(0,0,0,1) 100%);
    background: linear-gradient(45deg, rgba(10, 10, 167, 1) 0%,rgba(0,0,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0a0aa7', endColorstr='#000000',GradientType=1 );
}

.layers .layersContainer .rightSide .cards ul li h1 {
    font-size: 2em;
    margin-top: 8%;
    margin-bottom: 10%;
    transition: .4s;
}

.layers .layersContainer .rightSide .cards ul li:nth-child(3) h1 {
    background: rgb(177, 2, 2);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.layers .layersContainer .rightSide .cards ul li:nth-child(2) h1 {
    background: rgb(5, 107, 5);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.layers .layersContainer .rightSide .cards ul li:nth-child(1) h1 {
    background: rgb(10, 10, 167);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.layers .layersContainer .rightSide .cards ul li > span {
    font-size: 1.1em;
    color: #999999;
}

.layers .layersContainer .rightSide .cards ul li:nth-child(1) {
    transform:  rotate(20deg) skew(-20deg) translate(10px,10px); 
}
 
.layers .layersContainer .rightSide .cards ul li:nth-child(2) {
    transform:  rotate(20deg) skew(-20deg) translate(0px,0px); 
}

.layers .layersContainer .rightSide .cards ul li:nth-child(3) {
    transform:  rotate(20deg) skew(-20deg) translate(-10px,-10px); 
}

.layers .layersContainer .rightSide .cards ul:hover li:nth-child(1) {
    transform:  rotate(40deg) skew(0) translate(100px,0) ; 
}

.layers .layersContainer .rightSide .cards ul:hover li:nth-child(2) {
    transform:  rotate(0) skew(0); 
}

.layers .layersContainer .rightSide .cards ul:hover li:nth-child(3) {
    transform:  rotate(-40deg) skew(0) translate(-100px,0) ; 
}

.layers .layersContainer .rightSide .cards ul:hover li:hover {
    z-index: 9;
    background-color: #0e1826;
    box-shadow:10px 10px 40px rgba(0,0,0,0.5) ;
} 
 
.layers .layersContainer .rightSide .cards ul:hover li:hover::after {
    height:100%;
 /*   transform:skew(30deg); */
}

.layers .layersContainer .rightSide .cards ul:hover li:hover * {
    color: #fff;
    background: -webkit-linear-gradient(#fff, #fff);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}