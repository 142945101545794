.principalNav {
    position: absolute;
    width: 100%;
    height: 15vh;
    background: rgba(255, 255, 255, 0.2);
    z-index: 100;
}

.principalNav .containerNav {
    position: absolute;
    left: 50%;
    width: 100%;
    height: 15vh;
    max-width: 1140px;
    transform: translateX(-50%);
    border-bottom: 2px solid #960000;
}

.principalNav .containerNav .loginBar {
    width: 100%;
    height: 30%;
    padding: 0;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
}

.principalNav .containerNav .language {
    width: 150px;
    height: 100%;
    display: inline-flex;
    align-items: center;
}

.principalNav .containerNav .loginBar .language .languageIcon {
    font-size: 1.5rem;
    margin-right: 5%;
    color: black;
}

.principalNav .containerNav .loginBar .language .contentSelect {
    position: relative;
    display: flex;
    align-items: center;
}

.principalNav .containerNav .loginBar .language .contentSelect .languageSelect {
    background: transparent;
    border: none;
    width: 120px;
    height: 30px;
    outline: none;
    font-family: 'Open Sans', sans-serif;
    font-style: italic;
    color: rgb(2, 5, 104);
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    transition: all 0.25s ease;
}

.principalNav .containerNav .loginBar .language .contentSelect .languageSelect:hover {
    font-weight: bold;
}

.principalNav .containerNav .loginBar .language .contentSelect .languageSelect::-ms-expand {
    display: none;
}

.principalNav .containerNav .loginBar .language .contentSelect i {
    position: absolute;
    right: 20px;
    top: calc(70% - 13px);
    width: 12px;
    height: 12px;
    display: block;
    border-left: 3px solid black;
    border-bottom: 3px solid black;
    transform: rotate(-45deg);
    transition: all 0.25s ease;
}

.principalNav .containerNav .loginBar .language .contentSelect:hover i {
    margin-top: 3px;
}

.principalNav .containerNav .loginBar .separator {
    width: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(2, 5, 104);
    font-weight: bolder;
    font-size: 1.4rem;
}

.principalNav .containerNav .loginBar .login {
    height: 100%;
    display: inline-flex;
    align-items: center;
    font-family: 'Open Sans', sans-serif;
    color: rgb(2, 5, 104);
    font-weight: bold;
    text-align: right;
    justify-content: flex-end;
    margin-left: 1%;
}

.principalNav .containerNav .loginBar .login .userIcon {
    font-size: 1rem;
    color: black;
}

.principalNav .containerNav .loginBar .login .userText {
    margin-left: 10px;
    font-style: italic;
}

.principalNav .containerNav .menuBar {
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: row;
}

.principalNav .containerNav .menuBar .logo {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.principalNav .containerNav .menuBar .logo .navLogo {
    width: 60%;
}

.principalNav .containerNav .menuBar .menu {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-family: 'Montserrat', sans-serif;
    color: black;
}

.principalNav .containerNav .menuBar .menu ul {
    list-style: none;
}

.principalNav .containerNav .menuBar .menu ul li {
    display: inline-flex;
    justify-content: space-between;
    width: 120px;
}

.principalNav .containerNav .menuBar .menu ul li a {
    text-decoration: none;
    transition: all 0.25s ease;
    color: black;
    position: relative;
    transition: all 0.25s ease;
}

.principalNav .containerNav .menuBar .menu ul li a::before {
    content: '>';
    color: #960000;
    top: 0;
    left: -15px;
    opacity: 0;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
}

.principalNav .containerNav .menuBar .menu ul li a::after {
    content: '<';
    color: #960000;
    top: 0;
    right: -15px;
    opacity: 0;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
}

.principalNav .containerNav .menuBar .menu ul li a:hover,
.principalNav .containerNav .menuBar .menu ul li a.active {
    color: #960000;
}

.principalNav .containerNav .menuBar .menu ul li a:hover::before,
.principalNav .containerNav .menuBar .menu ul li a.active::before {
    opacity: 1;
}

.principalNav .containerNav .menuBar .menu ul li a:hover::after,
.principalNav .containerNav .menuBar .menu ul li a.active::after {
    opacity: 1;
}