.contactImg {
    width: 100%;
    height: 450px;
    background: url('./pics/contactus.jpg') no-repeat;
    background-size: 100%;
    background-position: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contactImg h1 {
    color: #3F3D3D;
    font-family: 'Montserrat', sans-serif;
    font-weight: bolder;
    text-align: center;
}

.contactContainer {
    background: white;
    position: absolute;
    top: 400px;
    left: 0;
    width: 100%;
    height: 50%;
    margin-left: 50%;
    max-width: 1140px;
    transform: translateX(-50%);
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    padding: 20px;
    padding-top: 50px;
    display: flex;
    flex-direction: row;
    margin-bottom: 10%;
}

.contactImg h1{
    margin-top: 10%;
}

.contactContainer .contactLeft {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contactContainer .contactLeft .contactLogo {
    width: 50%;
    margin-left: 35%;
}

.contactContainer .contactRight {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contactContainer .contactRight form {
    width: 60%;
    height: 90%;
}
.contactRight h1 {
    margin-top: -60%;
    width: 50%;
    margin-left: 15%;
}


.contactContainer .contactRight form .contactGrpControls {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.contactContainer .contactRight form .contactGrpControls label {
    color: #3F3D3D;
    font-family: 'Montserrat', sans-serif;
    font-weight: bolder;
    font-size: 0.8rem;
    margin-bottom: 5px;
}

.contactContainer .contactRight form .contactGrpControls input,
.contactContainer .contactRight form .contactGrpControls textarea {
    padding: 5px;
    border: 1px solid #3F3D3D;
    border-radius: 5px;
    outline: none;
}

.contactContainer .contactRight form button {
    padding: 15px;
    margin-top: 5%;
    background: none;
    border: 1px solid #960000;
    border-radius: 8px;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    color: #978E8E;
    transition: all .6s ease;
    width: 35%;
    margin-left: 50%;
    transform: translateX(-50%);
}

.contactContainer .contactRight form button:hover {
    color: white;
    background: #960000;
}