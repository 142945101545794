.services {
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 5%;
    background: rgba(170, 170, 170, 0.12);
    padding: 20px;
}

.services .servicesContainer {
    width: 100%;
    height: 100%;
    margin-left: 50%;
    max-width: 1140px;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.services .servicesContainer h1 {
    color: #3F3D3D;
    font-family: 'Montserrat', sans-serif;
    font-weight: bolder;
    text-align: center;
}

.services .servicesContainer h1::after {
    content: '';
    display: block;
    margin: 5px auto;
    background: none;
    border-bottom: 1px solid #960000;
    width: 80%;
}

.services .servicesContainer p {
    color: #978E8E;
    font-family: 'Montserrat', sans-serif;
    margin-top: 8px;
    text-align: center;
    width: 85%;
}

.services .servicesContainer .serviceCards {
    width: 85%;
    margin-top: 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.services .servicesContainer .serviceCards .serviceCard {
    width: 200px;
    height: 330px;
    border-radius: 8px;
    box-shadow: 2px 5px 4px #888888;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background: white;
    transition: all 0.6s ease;
}

.services .servicesContainer .serviceCards .serviceCard:hover {
    transform: translateY(-20px);
}

.services .servicesContainer .serviceCards .serviceCard img {
    width: 160px;
    border-radius: 10px;
    margin-top: 20px;
}

.services .servicesContainer .serviceCards .serviceCard h3 {
    color: black;
    font-family: 'Montserrat', sans-serif;
    font-weight: lighter;
    font-style: italic;
    margin-top: 12px;
    text-align: center;
    width: 85%;
}

.services .servicesContainer .serviceCards .serviceCard p {
    font-family: 'Open Sans', sans-serif;
    font-style: italic;
    margin-top: 12px;
}

.serviceCard a {
    font-size: 15px;
    text-align: center;
}

.services .servicesContainer .serviceCards .serviceCard a {
    padding: 10px;
    margin-top: 5%;
    background: none;
    border: 1px solid #960000;
    border-radius: 8px;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    color: #978E8E;
    transition: all .6s ease;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 5%;
    width: 70%;
    text-decoration: none;
}

.services .servicesContainer .serviceCards .serviceCard a:hover {
    color: white;
    background: #960000;
}
