.sliderSm {
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 5%;
    padding-bottom: 2%;
}

.sliderSm .sliderSmContainer {
    width: 100%;
    height: 100%;
    margin-left: 50%;
    max-width: 1140px;
    transform: translateX(-50%);
    position: relative;
}

.sliderSm .sliderSmContainer h1 {
    color: #3F3D3D;
    font-family: 'Montserrat', sans-serif;
    font-weight: bolder;
    text-align: center;
}

.sliderSm .sliderSmContainer .slideSm {    
    overflow: hidden;
    display: flex;
    flex-direction: row;
    width: 80%;
    margin: 0 auto;
    margin-top: 3%;
    transition: all 0.6s ease;
    padding-bottom: 1%;
}

.sliderSm .sliderSmContainer .slideSm .slideItem {
    width: 100px;
    height: 100px;
    border-radius: 8px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    min-width: 100px;
    margin-left: 30px;
    box-shadow: 3px 3px 8px rgba(0,0,0,0.5);
    transition: all 0.6s ease;
}

.sliderSm .sliderSmContainer .slideSm .slideItem .slideSmImage {
    width: 80%;
}

.sliderSm .sliderSmContainer .slideSmControls {
    width: 85%;
    position: absolute;
    top: 65%;
    left: 0;
    display: flex;
    margin-left: 51%;
    transform: translateX(-50%);
    justify-content: space-between;
}

.sliderSm .sliderSmContainer .slideSmControls button {
    width: 25px;
    height: 25px;
    border: none;
    border-radius: 50%;
    outline: none;
    background: transparent;
    color: #960000;
    font-size: 1.3rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sliderSm .sliderSmContainer .slideSmControls button:hover {
    background: #960000;
    color: white;
    border-radius: 50%;
}