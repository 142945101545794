.construction {
        background: white;
        position: absolute;
        top: 300px;
        left: 0;
        width: 100%;
        height: auto;
        margin-left: 50%;
        max-width: 1140px;
        transform: translateX(-50%);
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        padding: 20px;
        margin-top: -160px;
        background: url('./pics/construction.png') - no-repeat;
        text-align: right;
}

.construction h1{
    color: black;
    text-align: left;
    font-family: 'Montserrat', sans-serif;
    font-size: 25px;
}