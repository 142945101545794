footer {
    position: relative;
    width: 100%;
    height: auto;
    padding: 20px;
    /*background: rgba(212, 231, 250, 1);*/
}

footer .footerContainer {
    width: 100%;
    height: 100%;
    margin-left: 50%;
    max-width: 1140px;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
}

footer .footerContainer .footerLogo {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 25px;
}

footer .footerContainer .footerLogo .contactLogo {
    width: 40%;
}

footer .footerContainer .footerContact {
    width: 50%;
    display: flex;
    color: black;
    justify-content: flex-end;
    font-family: 'Montserrat', sans-serif;
    font-weight: lighter;
    font-size: 1rem;
}

footer .footerContainer .footerContact h4 {
    text-align: left;
}

footer .footerContainer .footerContact h4::after {
    content: '';
    display: block;
    margin: 5px 0;
    background: none;
    border-bottom: 1px solid #960000;
    width: 100%;
}

footer .footerContainer .footerContact ul {
    list-style: none;
    width: 80%;
}

footer .footerContainer .footerContact ul li {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 45px;
    margin-bottom: 5px;
    word-wrap: break-word;
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    font-size: 0.9rem;
    color: rgb(2, 5, 104);
}

footer .footerContainer .footerContact ul li .contactIcon {
    min-width: 45px;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    color: white;
    background: black;
    border-radius: 8px;
    margin-right: 15px;
}

footer .footerCopy p.copy {
    width: 100%;
    text-align: center;
    font-style: italic;
    font-size: 0.9rem;
    margin-top: 3%;
}