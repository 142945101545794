.mobile {
    position: relative;
    width: 100%;
    height: 450px;
    margin-top: 5%;
}

.mobile .mobileBg {
    position: absolute;
    height: 450px;
    right: 0;
    top: 0;
    width: 40%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background: -moz-linear-gradient(90deg, rgba(212, 231, 250, 1) 0%, rgba(0,0,0,0) 100%);
    background: -webkit-linear-gradient(90deg, rgba(212, 231, 250, 1) 0%, rgba(0,0,0,0) 100%);
    background: linear-gradient(90deg, rgba(212, 231, 250, 1)  0%, rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d4e7fa', endColorstr='#000000',GradientType=1 );
}

.mobile .mobileContainer {
    width: 100%;
    height: 100%;
    margin-left: 50%;
    max-width: 1140px;
    transform: translateX(-50%);
    display: flex;
}

.mobile .mobileContainer .mobileLeft {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mobile .mobileContainer .mobileLeft h1 {
    color: #3F3D3D;
    font-family: 'Montserrat', sans-serif;
    font-weight: bolder;
    text-align: center;
}

.mobile .mobileContainer .mobileLeft p {
    width: 85%;
    color: #978E8E;
    font-family: 'Montserrat', sans-serif;
    margin-top: 15px;
    text-align: center;
}

.mobile .mobileContainer .mobileLeft a {
    padding: 15px;
    margin-top: 5%;
    background: none;
    border: 1px solid #960000;
    border-radius: 8px;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    color: #978E8E;
    transition: all .6s ease;
    text-decoration: none;
    font-size: 15px;
}

.mobile .mobileContainer .mobileLeft a:hover {
    color: white;
    background: #960000;
}

.mobile .mobileContainer .mobileRight {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mobile .mobileContainer .mobileRight .mobileImg {
    width: 70%;
    margin-right: 30%;
}

