.aboutImg {
    width: 100%;
    height: 450px;
    background: url('./pics/team.jpg') no-repeat;
    background-size: 100%;
    background-position: center;
    position: relative;
}

.aboutContainer {
    background: white;
    position: absolute;
    top: 400px;
    left: 0;
    width: 100%;
    height: auto;
    margin-left: 50%;
    max-width: 1140px;
    transform: translateX(-50%);
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    padding: 20px;
    padding-top: 50px;
}

.aboutContainer .aboutNos {
    display: flex;
    flex-direction: row;
}

.aboutContainer .aboutNos .aboutNosImg {
    width: 25%;
}

.aboutContainer .aboutNos .aboutNosImg img {
    width: 50%;
    transform: translateX(50%);
}

.aboutContainer .aboutNos .aboutNosText {
    width: 75%;
}

.aboutContainer .aboutNos .aboutNosText h1 {
    color: #3F3D3D;
    font-family: 'Montserrat', sans-serif;
    font-weight: bolder;   
}

.aboutContainer .aboutNos .aboutNosText h1::after {
    content: '';
    display: block;
    margin: 5px 0px;
    background: none;
    border-bottom: 1px solid #960000;
    width: 50%;
}

.aboutContainer .aboutNos .aboutNosText p {
    font-style: italic;
}

.aboutContainer .aboutMVV {
    display: flex;
    justify-content: space-around;
    margin-top: 5%;
    margin-bottom: 5%;
}

.aboutContainer .aboutMVV .aboutColum {
    width: 300px;
    padding: 15px;
    border-radius: 15px;
    box-shadow: 5px 5px 5px rgba(0,0,0,0.3);
}

.aboutContainer .aboutMVV .aboutColum h1 {
    color: #3F3D3D;
    font-family: 'Montserrat', sans-serif;
    font-weight: bolder;   
    text-align: center;
}

.aboutContainer .aboutMVV .aboutColum h1::after {
    content: '';
    display: block;
    margin: 5px auto;
    background: none;
    border-bottom: 1px solid #960000;
    width: 20%;
}

.aboutContainer .aboutMVV .aboutColum p {
    margin-top: 4%;
    font-style: italic;
    line-height: 25px;
}